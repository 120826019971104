import React from 'react'
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
// import Instagram from "../components/instagram"

import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

export default function Paint() {
    return (
        <Layout>
        <SEO title="Detailing Packages" />
            <div className="masthead white paint">
                <Container>
                    <Row><h1>Paint Enhancement</h1>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
                        <Breadcrumb.Item active>Paint Enhancement</Breadcrumb.Item>
                    </Breadcrumb>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col className="tab-content">
                        <h3>The Bravo-1 Paint Enhancement Package</h3>
                        <p>Your vehicle will receive a thorough rinse followed by a foam bath and a thorough 2 bucket hand wash which will remove any bugs, bird droppings and tar. It will then be clay bar treated, decontaminated, and hand dried. The tires are scrubbed clean &amp; dressed, the rims are deep cleaned using soft brushes, and the wheel wells are cleaned.  We will then assess the paint and begin a full 1 step polish which will bring back supreme depth, clarity and remove minor scratches and marring. The surface is then prepped and our M2116 Synthetic Sealant 2.0 is applied to the paints surface. The windows are cleaned and any chrome exhaust tips are polished and protected. This will have your paint shining like you’ve never seen before! Give your vehicle an amazing transformation with this paint enhancement package!</p>              
                    </Col>
                </Row>
                <Row className="pricing-container painting-pricing">
                    <div className="pricing-block">
                        <h3>Small Vehicles</h3>
                        <h4>From<br/>$450</h4>
                    </div>        
                    <div className="pricing-block">
                        <h3>Mid-size Vehicles</h3>
                        <h4>From<br/>$500</h4>
                    </div>        
                    <div className="pricing-block">
                        <h3>Large Vehicles</h3>
                        <h4>From<br/>$550</h4>
                    </div>        
                    <div className="pricing-block">
                        <h3>XL</h3>
                        <h4>From<br/>$600</h4>
                    </div>        
                </Row>
            </Container>
            </Layout>
    )
}


